/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { IconModule } from '@visurel/iconify-angular';
import { NavigationItemModule } from 'src/@vex/components/navigation-item/navigation-item.module';
import { PopoverModule } from 'src/@vex/components/popover/popover.module';
import { ContainerModule } from 'src/@vex/directives/container/container.module';
import { LangMenuModule } from 'src/app/components/lang-menu/lang-menu.module';
import { CommonDirectivesModule } from 'src/app/directives/common-directives.module';
import { NavigationModule } from '../navigation/navigation.module';
import { ToolbarAnnouncementsModule } from './toolbar-announcements/toolbar-announcements.module';
import { ToolbarUserModule } from './toolbar-user/toolbar-user.module';
import { ToolbarComponent } from './toolbar.component';

@NgModule({
  declarations: [ToolbarComponent],
  imports: [
    CommonDirectivesModule,
    CommonModule,
    ContainerModule,
    FlexLayoutModule,
    IconModule,
    LangMenuModule,
    MatBadgeModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatRippleModule,
    NavigationItemModule,
    NavigationModule,
    PopoverModule,
    RouterModule,
    ToolbarAnnouncementsModule,
    ToolbarUserModule,
    TranslocoModule,
  ],
  exports: [ToolbarComponent],
})
export class ToolbarModule {}
